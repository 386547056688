<template>
  <div class="row">
    <div class="col-md-12">
      <h2 class="pb-3">Einstellungen - Passwort ändern</h2>
      <div class="col-md-6">
        <div class="card mb-3">
          <div class="card-header">
            <b>Benutzer: {{username}}</b>
          </div>
          <div class="card-body">
            <form @submit.prevent="changePassword()">
              <div class="mb-3">
                <label for="currentPassword" class="form-label">Aktuelles Passwort</label>
                <input type="password" class="form-control" id="currentPassword" v-model="currentPassword" placeholder="Aktuelles Passwort" required>
              </div>
              <div class="mb-3">
                <label for="newPassword" class="form-label"> Neues Passwort</label>
                <input type="password" class="form-control" id="newPassword" v-model="newPassword" placeholder="Neues Passwort" required>
                <small id="rangeHelpBlock" class="form-text text-muted ml-1">
                  Muss 8-20 Zeichen lang sein und Groß-/Kleinbuchstaben, Zahlen sowie Sonderzeichen enthalten.
                </small>
              </div>
              <div class="mb-4">
                <label for="newPasswordRepeat" class="form-label">Neues Passwort wiederholen</label>
                <input type="password" class="form-control" id="newPasswordRepeat" v-model="newPasswordRepeat" placeholder="Neues Passwort wiederholen" required>
              </div>
              <div class="mb-3 mt-4">
                <button class="btn btn-primary btn-block"><i class="bi bi-key"></i> Passwort ändern</button>
              </div>
            </form>
          </div>
        </div>
        <div class="alert alert-danger" role="alert" v-if="formValidationErrors.length > 0">
          <b>Bitte die folgenden Fehler beheben:</b>
            <ul>
              <li v-for="error in formValidationErrors" :key="error">{{ error }}</li>
            </ul>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { firebaseApp } from '../firebaseDb'
import store from "../store"
export default {
  name: "app",
  components: {
  },
  data () {
    return {
      username: store.getters.user.data.email,
      formValidationErrors: [],
      newPassword: null,
      newPasswordRepeat: null,
      currentPassword: null
    }
  },
  created () {
  },
  methods: {
    changePassword: function () {
      this.formValidationErrors = []
      if (this.newPassword !== this.newPasswordRepeat) {
        this.formValidationErrors.push("Die eingebenen Passwörter sind nicht identisch.")
      }
      if (this.newPassword.length < 8) {
        this.formValidationErrors.push("Das Passwort ist nicht ausreichend lang (mind. 8 Zeichen).")
      }
      if (this.newPassword.length > 20) {
        this.formValidationErrors.push("Das Passwort ist zu lang (max. 20 Zeichen)")
      }
      if (!this.newPassword.match(/[a-z]+/)) {
        this.formValidationErrors.push("Das Passwort enthält keine Kleinbuchstaben.")
      }
      if (!this.newPassword.match(/[A-Z]+/)) {
        this.formValidationErrors.push("Das Passwort enthält keine Großbuchstaben.")
      }
      if (!this.newPassword.match(/[0-9]+/)) {
        this.formValidationErrors.push("Das Passwort enthält keine Ziffern.")
      }
      if (!this.newPassword.match(/[$@$!%*?&+-.,]+/)) {
        this.formValidationErrors.push("Das Passwort enthält keine Sonderzeichen")
      }

      if (this.formValidationErrors.length < 1) {
        const user = firebaseApp.auth().currentUser
        console.log('user ' + user)
        user.updatePassword(this.newPassword).then(() => {
          this.$toast.success("Passwort wurde erfolgreich geändert.")
          this.newPassword = null
          this.newPasswordRepeat = null
        }).catch((error) => {
          if (error.code === 'auth/requires-recent-login') {
            console.log('reauth')
            const credential = firebaseApp.auth.EmailAuthProvider.credential(
              this.username,
              this.currentPassword
            )
            user.reauthenticateWithCredential(credential).then(() => {
              user.updatePassword(this.newPassword).then(() => {
                this.$toast.success("Passwort wurde erfolgreich geändert.")
                this.newPassword = null
                this.newPasswordRepeat = null
                this.currentPassword = null
              })
            }).catch(() => {
              this.$toast.error("Fehler beim Ändern des Passworts.")
              this.newPassword = null
              this.newPasswordRepeat = null
              this.currentPassword = null
            })
          } else {
            this.$toast.error("Fehler beim Ändern des Passworts.")
            this.newPassword = null
            this.newPasswordRepeat = null
            this.currentPassword = null
          }

          console.log(error)
        })
      }
    }
  },
  computed: {
}
}

</script>
